<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="customerPool-container">
    <div class="title-container"><div>客户中心</div></div>
    <div class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">累计成交客户总数</div>
        <div class="item-black">{{ totalNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">正在服务客户数</div>
        <div
          @click="
            () => {
              collect(
                'serviceNum',
                '正在服务客户数',
                [{ name: '客户名称', type: 'input', modelKey: 'name' },
                { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                { name: '销售负责人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '客户划分', colProp: ['cusTypeName'] },
                  { name: '客户状态', colProp: ['customerStateName'] },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '创建时间', colProp: ['createTime'] },
                ]
              );
            }
          "
          class="item-num"
        >
          {{ serviceNum || "0" }}
        </div>
      </div>
      <div class="Summary-item">
        <div class="item-title">待服务客户数</div>
        <div
          @click="
            () => {
              collect(
                'willServiceNum',
                '待服务客户数',
                [{ name: '客户名称', type: 'input', modelKey: 'name' },
                { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                { name: '销售负责人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '客户划分', colProp: ['cusTypeName'] },
                  { name: '客户状态', colProp: ['customerStateName'] },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '创建时间', colProp: ['createTime'] },
                ]
              );
            }
          "
          class="item-num"
        >
          {{ willServiceNum || "0" }}
        </div>
      </div>
      <div class="Summary-item Summary-item-line">
        <div class="item-title">终止服务客户数</div>
        <div
          @click="
            () => {
              collect(
                'endServiceNum',
                '终止服务客户数',
                [{ name: '客户名称', type: 'input', modelKey: 'name' },
                { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                { name: '销售负责人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '客户划分', colProp: ['cusTypeName'] },
                  { name: '客户状态', colProp: ['customerStateName'] },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '创建时间', colProp: ['createTime'] },
                ]
              );
            }
          "
          class="item-num"
        >
          {{ endServiceNum || "0" }}
        </div>
      </div>
      <div class="Summary-item" style="align-items: center;">
        <el-select style="width: 80px;margin-left: 32px" v-model="topDate" @change="changeRadio">
          <el-option label="今日" :value="1"></el-option>
          <el-option label="昨日" :value="2"></el-option>
          <el-option label="本周" :value="3"></el-option>
          <el-option label="本月" :value="5"></el-option>
        </el-select>
      </div>
      <div v-show="topDate == 2" class="Summary-item">
        <div class="item-title">新增成交客户数</div>
        <div
          @click="
            () => {
              collect(
                'addCustomerNum',
                '新增成交客户数',
                [{ name: '客户名称', type: 'input', modelKey: 'name' },
                { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                { name: '客户状态', type: 'select', modelKey: 'customerState',selOpt: [{id: '1',name: '正在服务'},{id: '2',name: '待服务'},{id: '3',name: '服务终止'}] },
                { name: '销售负责人', type: 'input', modelKey: 'createName' },],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '客户划分', colProp: ['cusTypeName'] },
                  { name: '客户状态', colProp: ['customerStateName'] },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '创建时间', colProp: ['ordCreateTime'] },
                ],
                'needDate'
              );
            }
          "
          class="item-num"
        >
          {{ addCustomerNum || "0" }}
        </div>
      </div>
      <div v-show="topDate == 1" class="Summary-item">
        <div class="item-title">新增成交客户数</div>
        <div @click="
            () => {
              collect(
                'addCustomerNum',
                '新增成交客户数',
                [
                { name: '客户名称', type: 'input', modelKey: 'name' },
                { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                { name: '客户状态', type: 'select', modelKey: 'customerState',selOpt: [{id: '1',name: '正在服务'},{id: '2',name: '待服务'},{id: '3',name: '服务终止'}] },
                { name: '销售负责人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '客户划分', colProp: ['cusTypeName'] },
                  { name: '客户状态', colProp: ['customerStateName'] },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '创建时间', colProp: ['ordCreateTime'] },
                ],
                'needDate'
              );
            }
          " class="item-num">{{ addCustomerNum || "0" }}</div>
      </div>
      <div v-show="topDate == 3" class="Summary-item">
        <div class="item-title">新增成交客户数</div>
        <div @click="
            () => {
              collect(
                'addCustomerNum',
                '新增成交客户数',
                [
                  { name: '客户名称', type: 'input', modelKey: 'name' },
                { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                { name: '客户状态', type: 'select', modelKey: 'customerState',selOpt: [{id: '1',name: '正在服务'},{id: '2',name: '待服务'},{id: '3',name: '服务终止'}] },
                { name: '销售负责人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '客户划分', colProp: ['cusTypeName'] },
                  { name: '客户状态', colProp: ['customerStateName'] },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '创建时间', colProp: ['ordCreateTime'] },
                ],
                'needDate'
              );
            }
          " class="item-num">{{ addCustomerNum || "0" }}</div>
      </div>
      <div v-show="topDate == 5" class="Summary-item">
        <div class="item-title">新增成交客户数</div>
        <div @click="
            () => {
              collect(
                'addCustomerNum',
                '新增成交客户数',
                [
                  { name: '客户名称', type: 'input', modelKey: 'name' },
                { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                { name: '客户状态', type: 'select', modelKey: 'customerState',selOpt: [{id: '1',name: '正在服务'},{id: '2',name: '待服务'},{id: '3',name: '服务终止'}] },
                { name: '销售负责人', type: 'input', modelKey: 'createName' },
                ],
                [
                 { name: '客户名称', colProp: ['name'] },
                  { name: '客户划分', colProp: ['cusTypeName'] },
                  { name: '客户状态', colProp: ['customerStateName'] },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '创建时间', colProp: ['ordCreateTime'] },
                ],
                'needDate'
              );
            }
          "  class="item-num">{{ addCustomerNum || "0" }}</div>
      </div>
    </div>
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
          clearable
          @clear="() => clearInput()"
        ></el-input>
      </div>
      <!-- <div class="common-input-container">
        <span>客户状态:</span>
        <el-select
          class="common-screen-input"
          v-model="params.state"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option label="已成交" value="1"> </el-option>
          <el-option label="未成交" value="2"> </el-option>
        </el-select>
      </div> -->
      <div class="common-input-container">
        <span>客户划分:</span>
        <el-select class="common-screen-input" v-model="params.projectType" @change="commonFun" placeholder="请选择" filterable clearable>
          <el-option label="项目" :value="217"> </el-option>
          <el-option label="散号" :value="218"> </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户级别:</span>
        <el-select
          v-if="custDict['1']"
          class="common-screen-input"
          v-model="params.level"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['1']"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>业务类型:</span>
        <el-select
          class="common-screen-input"
          v-model="params.type"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in typeOption"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户状态:</span>
        <el-select
          class="common-screen-input"
          v-model="params.customerState"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in customerStateList"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户来源:</span>
        <el-select
          v-if="custDict['2']"
          class="common-screen-input"
          v-model="params.source"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['2']"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="common-input-container">
        <span>服务状态:</span>
         <el-select
          class="common-screen-input"
          v-model="params.shopState" placeholder="请选择" @change="commonFun" clearable filterable>
          <el-option key="3" label="暂无服务" value="3"></el-option>
          <el-option key="2" label="待服务" value="2"></el-option>
          <el-option key="1" label="正在服务" value="1"></el-option>
          <el-option key="0" label="服务终止" value="0"></el-option>
        </el-select>
      </div> -->
      <div class="common-input-container">
        <span>行业:</span>
        <el-select
          class="common-screen-input"
          v-model="params.industry"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['6']"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>地区:</span>
        <a-cascader
          class="common-screen-input"
          :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          v-model="params.areaId"
          :options="options"
          :load-data="loadData"
          placeholder="请选择"
          change-on-select
          @change="commonFun"
        />
      </div>
      <div v-show="activeName == 'first'" class="common-input-container">
        <span>客户负责人:</span>
        <el-select
          class="common-screen-input"
          v-model="params.chargeUserId"
          @change="commonFun"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in responsible"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <common-date @commonGetDate="commonGetDate" ref="commonReset"></common-date>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="() => commonFun()"
        >查 询</el-button
      >
      <!-- <el-button
        class="common-screen-btn"
        type="primary"
        @click="() => getFinishedList()"
        >查 询</el-button
      > -->
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <div class="gap"></div>

    <!-- 所有  我的 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-if="rolesName=='公司管理员'||rolesName=='销售主管'||rolesName=='超级管理员'" label="所有客户" name="first">
        <el-table class="common-table-haveborder common-table-bgGrey th" :data="tableData" border>
      <el-table-column prop="name" label="客户名称">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => getCustDeatil(scope.row)"
            >{{ scope.row.name || "--" }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="project_type"
        label="客户划分"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="levelName"
        label="客户级别"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="typeName" label="业务类型" :formatter="tableColumn">
      </el-table-column>
      <el-table-column label="客户状态">
        <template slot-scope="scope">
          <div class="common-tableStatus-green-bgc" v-if="scope.row.customerState == '1'">正在服务</div>
          <div class="common-tableStatus-blue-bgc"  v-if="scope.row.customerState == '2'">待服务</div>
          <div class="common-tableStatus-grey-bgc"  v-if="scope.row.customerState == '3'">服务终止</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="服务状态">
        <template slot-scope="scope">
          <div v-if="scope.row.shopState == 0" class="common-tableStatus-red">服务终止</div>
          <div v-if="scope.row.shopState == 1" class="common-tableStatus-green">正在服务</div>
          <div v-if="scope.row.shopState == 2" class="common-tableStatus-blue">待服务</div>
          <div v-if="scope.row.shopState == 3" class="common-tableStatus-grey">暂无服务</div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="sourceName"
        label="客户来源"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="industryName"
        label="行业"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="areaName"
        label="地区"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="note" label="备注">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.note"
            class="item"
            effect="dark"
            :content="scope.row.note"
            placement="bottom"
          >
            <div class="common-overflow-drop">{{ scope.row.note }}</div>
          </el-tooltip>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="chargeUserName"
        label="客户负责人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="110"
        :formatter="tableColumn"
      ></el-table-column>
        </el-table>
        <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="我的客户" name="second">
        <el-table class="common-table-haveborder common-table-bgGrey th" :data="tableData" border>
      <el-table-column prop="name" label="客户名称">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => getCustDeatil(scope.row)"
            >{{ scope.row.name || "--" }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="project_type"
        label="客户划分"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="levelName"
        label="客户级别"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="typeName" label="业务类型" :formatter="tableColumn">
      </el-table-column>
      <el-table-column label="客户状态">
        <template slot-scope="scope">
          <div class="common-tableStatus-green-bgc" v-if="scope.row.customerState == '1'">正在服务</div>
          <div class="common-tableStatus-blue-bgc"  v-if="scope.row.customerState == '2'">待服务</div>
          <div class="common-tableStatus-grey-bgc"  v-if="scope.row.customerState == '3'">服务终止</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="服务状态">
        <template slot-scope="scope">
          <div v-if="scope.row.shopState == 0" class="common-tableStatus-red">服务终止</div>
          <div v-if="scope.row.shopState == 1" class="common-tableStatus-green">正在服务</div>
          <div v-if="scope.row.shopState == 2" class="common-tableStatus-blue">待服务</div>
          <div v-if="scope.row.shopState == 3" class="common-tableStatus-grey">暂无服务</div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="sourceName"
        label="客户来源"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="industryName"
        label="行业"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="areaName"
        label="地区"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="note" label="备注">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.note"
            class="item"
            effect="dark"
            :content="scope.row.note"
            placement="bottom"
          >
            <div class="common-overflow-drop">{{ scope.row.note }}</div>
          </el-tooltip>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="chargeUserName"
        label="客户负责人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="110"
        :formatter="tableColumn"
      ></el-table-column>
        </el-table>
        <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    
    <pool-add
      ref="formPool"
      :drawer="drawer"
      :custDict="custDict"
      :adminInfo="adminInfo"
      :otherInfo="otherInfo"
      :customerInfo="customerInfo"
      :tableRow="tableRow"
      :loading="loading"
      @getCustDeatil="getCustDeatil"
      @handleClose="handleClose"
    ></pool-add>

    <!-- :methodFuc="poolHead" -->
    <!-- 头部报表弹 -->
    <common-sum-dialog
			:startDate="topStartDate"
			:endDate="topEndDate"
      ref="commonSumDialog"
      :methodFuc="customerTopList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import PoolAdd from "./children/customerPoolDetail.vue";
import { getFinishedList, seaDetail, poolHead, customerTopInfo, customerTopList } from "../../service/customer.js";
import { commonDict, sysAreaDetail, sysArea } from "../../service/common.js";
import { tableColumn, Config,getSectionDate,getLatelyDay,DateTransform } from "../../utils/index.js";
import { Cascader } from "ant-design-vue";
import CommonSumDialog from "../../components/common/commonSumDialog.vue";
import { mapState } from "vuex";
import { getRoleByType } from "../../service/common.js";
import CommonDate from "../../components/common/commonDate.vue"

export default {
  components: { Breadcrumb, PoolAdd, aCascader: Cascader, CommonSumDialog,CommonDate },
  data() {
    return {
      options: [],
      tableColumn,
      poolHead,
      customerTopList,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "营销", isLink: false },
        { title: "运营", isLink: false },
        { title: "客户中心", isLink: false }
      ],
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        // startTime:  DateTransform(new Date()),
        // endTime:  DateTransform(new Date()),
        headStartDate: DateTransform(new Date()),
        headEndDate: DateTransform(new Date()),
      },
      custDict: { "1": [], "2": [], "6": [], "8": [] }, // 字典值
      drawer: false, // 新增员工弹窗
      tableData: [],
      topStartDate: '',
			topEndDate: '',
      todayNew: 0,
      monthNew: 0,
      yesterdayNew: 0,
      addCustomerNum: 0,
      serviceNum: 0,
      willServiceNum: 0,
      endServiceNum: 0,
      weekNew: 0,
      totalNum: 0,
      adminInfo: "",
      otherInfo: {},
      customerInfo: "",
      tableRow: "",
      //////////////////////////////////////////////////
      headerType: "",
      dialogTit: "",
      dialogVisible: false,
      paramsTit: [],
      childColName: [],
      typeOption:[
        {id: 103,
          label: 'DSR修改'},
        {id: 104,
          label: '客服外包'},
        {id: 111,
          label: '电话销售客服'},
        {id: 144,
          label: '中差评修改'}
      ],
      topDate: 1,
      // 汇总弹窗所需数据
      commonSumVisible: false,
      activeName: 'first',
      rolesName: this.$sto.get(Config.constants.userInfo).roles[0],
      responsible: [], // 负责人下拉
      customerStateList: [
        {id: '1',name: '正在服务'},
        {id: '2',name: '待服务'},
        {id: '3',name: '服务终止'},
      ],
    };
  },
  created() {
    this.getComId()
    // this.getFinishedList();
    this.commonDict(1);
    this.commonDict(2);
    this.commonDict(6);
    this.commonDict(8);
    this.sysArea(0);
  },
  mounted(){
    if(this.$route.query.row){
      let row = {...JSON.parse(this.$route.query.row)}
      row.id = row.customerId
      this.getCustDeatil(row)
    }
  },
  computed: {
    ...mapState(["getUserInfo","comId"])  
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id;
        }
        if(this.rolesName=='公司管理员'||this.rolesName=='销售主管'||this.rolesName=='超级管理员'){
          if (this.$route.query.name && this.$route.query.id && this.$route.query.id != 'undefined') {
            this.params.name = this.$route.query.name
            this.getCustDeatil({ id: this.$route.query.id })
          }
          this.getFinishedList();
          // this.getRoleByType(0)
        }else{
          this.activeName = 'second'
          this.params.chargeUserId = this.$sto.get(Config.constants.userInfo).user_id
          if (this.$route.query.name && this.$route.query.id && this.$route.query.id != 'undefined') {
            this.params.name = this.$route.query.name
            this.getCustDeatil({ id: this.$route.query.id })
          }
          this.getFinishedList();
          // this.getRoleByType(0)
        }
        this.getRoleByType(0)
      },400)
    },
    async sysArea(id) {
      let resData = (await sysArea({ id: id })).data;
      resData.map((item) => {
        item.isLeaf = false;
      });
      this.options = resData;
    },
    async loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      let resData = (await sysArea({ id: targetOption.id })).data;
      resData.map((item) => {
        if (item.level != 3) {
          item.isLeaf = false;
        }
      });
      targetOption.children = resData;
      this.options = [...this.options];
      targetOption.loading = false;
    },
    clearInput() {
      // 筛选框清空-输入框
      this.getFinishedList();
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getFinishedList();
    },
    resetBtn() {
      this.$refs.commonReset.resetFun()
      // 重置
      this.params = {
        pageNum: 1,
        pageSize: 10,
        companyId: this.comId,
        // startTime:  DateTransform(new Date()),
        // endTime:  DateTransform(new Date()),
        headStartDate: DateTransform(new Date()),
        headEndDate: DateTransform(new Date()),
      };
      if(this.activeName == 'second'){
        this.params.chargeUserId = this.$sto.get(Config.constants.userInfo).user_id
      }
      this.topDate = 1
      this.getFinishedList();
    },
    async commonDict(type) {
      // 字典值接口
      let resData = (await commonDict({}, type)).data;
      this.custDict[`${type}`] = resData;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getFinishedList();
    },
    async getFinishedList() {
      // 获取已成交客户列表
      this.loading = true;
      let params = { ...this.params };
      if (params.areaId && params.areaId.length) {
        params.areaId = params.areaId[params.areaId.length - 1];
      } else {
        delete params.areaId;
      }
      let resData = (await getFinishedList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      // this.todayNew = resData.today;
      // this.monthNew = resData.month;
      this.totalNum = resData.totalNum;
      // this.yesterdayNew = resData.yesterday;
      this.addCustomerNum = resData.addCustomerNum
      this.serviceNum = resData.serviceNum
      this.willServiceNum = resData.willServiceNum
      this.endServiceNum = resData.endServiceNum
      // this.weekNew = resData.week;
      this.loading = false;
    },
    handleClose() {
      this.drawer = false;
      // 关闭弹窗
      this.getFinishedList();
    },
    async getCustDeatil(row) {
      // 获取客户详情
      if (row) {
        this.loading = true
        this.tableRow = row;
        let resData = (await seaDetail({ customerId: row.id })).data;
        this.drawer = true;
        this.loading = false;
        if (resData.customerDeatailVo && resData.customerDeatailVo.areaId) {
          resData.customerDeatailVo.areaId = resData.customerDeatailVo.areaId
            .toString()
            .split(",");
          resData.customerDeatailVo.areaName = (
            await sysAreaDetail({
              id:
                resData.customerDeatailVo.areaId[
                  resData.customerDeatailVo.areaId.length - 1
                ]
            })
          ).data;
          if (resData.customerDeatailVo.phone) {
            if (resData.customerDeatailVo.wx) {
              resData.customerDeatailVo.phoneWx = resData.customerDeatailVo.phone + ',' + resData.customerDeatailVo.wx
            } else {
              resData.customerDeatailVo.phoneWx = resData.customerDeatailVo.phone
            }
          } else {
            resData.customerDeatailVo.phoneWx = resData.customerDeatailVo.wx
          }
        }
        this.customerInfo = resData.customerDeatailVo || ""; // 客户信息
        this.adminInfo = resData.admin || ""; // 系统信息
        this.otherInfo = {
          totalPrice: resData.totalPrice,
          collectionPrice: resData.collectionPrice,
          historyCollectionPrice: resData.historyCollectionPrice
        };
        this.ruleFormAdd = {
          id: resData.customerDeatailVo.id || "",
          name: resData.customerDeatailVo.name || "",
          level: resData.customerDeatailVo.level || "",
          industry: resData.customerDeatailVo.industry || "",
          source: resData.customerDeatailVo.source || "",
          phone: resData.customerDeatailVo.phone || "",
          wx: resData.customerDeatailVo.wx || "",
          qq: resData.customerDeatailVo.qq || "",
          mail: resData.customerDeatailVo.mail || "",
          web: resData.customerDeatailVo.web || "",
          address: resData.customerDeatailVo.address || "",
          note: resData.customerDeatailVo.note || ""
        };
      }
      this.$refs.formPool.workLog(row.id);
      this.$refs.formPool.getShopList(row.id);
      this.$refs.formPool.getRenewalList(row.id);
      this.drawer = true;
    },
    /////////////////////////////////////////////////
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr,val) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.params.companyId,
        '',
        '',
        '',
        val?[this.params.headStartDate,this.params.headEndDate]: '',
      );
      this.commonSumVisible = true;
    },
    handleClose2() {
      this.dialogVisible = false;
    },
    getWeekDate () { // 获取本周日期
        let now = new Date(); //当前日期
        let nowDayOfWeek = now.getDay() || 7; //今天本周的第几天
        let nowDay = now.getDate(); //当前日
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        let weekStartDate = new Date(nowYear, nowMonth, nowDay - (nowDayOfWeek - 1));
        return [weekStartDate, new Date()]
    },
    changeRadio(e) {
        let date;
        switch (e) {
            case 1:
            date = [(new Date()), (new Date())];
            break;
            case 2:
            date = getLatelyDay(0);
            break;
            case 3:
            date = this.getWeekDate()
            break;
            case 5:
            date = getSectionDate('month')
            break;
        }
        this.customerTopInfo(DateTransform(date[0]), DateTransform(date[1]))
    },
    async customerTopInfo(startDate, endDate) { // 切换头部数据
			this.topStartDate = startDate
			this.topEndDate = endDate
      let params = {
        companyId: this.comId,
        startDate,
        endDate
      }
      this.loading = true
      let { data } = await customerTopInfo(params)
      this.loading = false;
      this.totalNum = data.totalNum;
      this.addCustomerNum = data.addCustomerNum
      this.serviceNum = data.serviceNum
      this.willServiceNum = data.willServiceNum
      this.endServiceNum = data.endServiceNum
    },
    handleClick(tab) {
      if(tab.name == 'second'){
        this.params.chargeUserId = this.$sto.get(Config.constants.userInfo).user_id
        this.getFinishedList()
      }else{
        delete this.params.chargeUserId
        this.getFinishedList()
      }
    },
    async getRoleByType(id) {
      let resData = (await getRoleByType({companyId: this.comId,}, id)).data;
      if (id == 0) {
        this.responsible = resData;
      } else if (id == 2) {
        this.assignPeople = resData;
      }
      console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz');
    },
    commonGetDate (startTime, endTime) {
      this.params.startTime = startTime
      this.params.endTime = endTime
      this.params.headStartDate = startTime
      this.params.headEndDate = endTime
      this.getFinishedList()
    },
  }
};
</script>
<style lang="less" scoped>
.customerPool-container {
  .common-screen-container {
    padding-left: 32px;
  }
  /deep/ .breadcrumb-container{
    padding: 0 32px;
  }
  /deep/ .el-tabs__content{
    padding: 0 32px;
  }
  /deep/.el-button {
    white-space: pre-line;
    text-align: left;
    line-height: 24px;
  }
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
/deep/ .el-table--border::after, 
.el-table--group::after{
  width: 0 !important;
}
.gap{
  position: absolute;
  left: 0;
  right: 0;
  height: 16px;
  background-color: #ededed;
}
// .common-table-haveborder{
//   margin: 64px auto 16px;
// }
.el-tabs {
  margin-top: 32px;
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
</style>
